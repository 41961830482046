/* https://www.modularscale.com/?18&px&1.15 */
:root {
  --font-size: 16px;
  --font-ratio: 1.10;
  --line-height: 1.5em;
  --h6: calc(var(--font-size) * var(--font-ratio));
  --h5: calc(var(--h6) * var(--font-ratio));
  --h4: calc(var(--h5) * var(--font-ratio));
  --h3: calc(var(--h4) * var(--font-ratio));
  --h2: calc(var(--h3) * var(--font-ratio));
  --h1: calc(var(--h2) * var(--font-ratio));
  --p: calc(var(--h6) * var(--font-ratio));
}

:root {
  --theme-color-1: #04153e;
  --theme-color-2: #d4d9e4;
  --theme-color-3: #a4b6ed;
  --theme-color-4: #3675f0;
  --font-color-1: #000;
  --body-font-family: sans-serif;
  --heading-font-family: 'Patua One', serif;

}

// Strict Document Styles
h1 {
  font-size: var(--h1);
}

h2 {
  font-size: var(--h2);
}

h3 {
  font-size: var(--h3);
}

h4 {
  font-size: var(--h4);
}

h5 {
  font-size: var(--h5);
}

h6 {
  font-size: var(--h6);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--font-color-1);
  font-weight: bold;
  line-height: 1.5em;
  font-family: var(--heading-font-family)
}

p, a {
  font-size: var(--p);
  line-height: var(--line-height);
}

html {
  font-size: var(--font-size);
  font-family: var(--body-font-family);
}