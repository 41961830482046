/* -------------------------------- 

File#: _3_mega-site-navigation
Title: Mega-Site Navigation
Descr: Navigation template for mega-sites
Usage: codyhouse.co/license

-------------------------------- */


/* variables */
:root {
  /* colors */
  --mn8-color-primary-hsl: 145, 45%, 7%;
  --mn8-color-bg-hsl: 0, 0%, 100%;
  --mn8-color-contrast-high-hsl: 230, 7%, 23%;
  --mn8-color-contrast-higher-hsl: 230, 13%, 9%;
  --mn8-color-contrast-lower-hsl: 240, 4%, 85%;
  --mn8-color-contrast-medium-hsl: 225, 4%, 47%;
  --mn8-color-bg-dark-hsl: 240, 4%, 95%;
  --mn8-color-white-hsl: 0, 0%, 100%;
  --mn8-color-primary-darker-hsl: 250, 84%, 38%;
  --mn8-color-primary-light-hsl: 250, 84%, 60%;
  --mn8-color-bg-lighter-hsl: 0, 0%, 100%;
  --mn8-color-black-hsl: 230, 13%, 9%;
  --mn8-color-bg-light-hsl: 0, 0%, 100%;
  --mn8-color-contrast-low-hsl: 240, 4%, 65%;
  --mn8-color-accent-hsl: 342, 89%, 48%;

  /* spacing */
  --mn8-space-4xs: 0.125rem;
  --mn8-space-3xs: 0.25rem;
  --mn8-space-2xs: 0.375rem;
  --mn8-space-xs: 0.5rem;
  --mn8-space-sm: 0.75rem;
  --mn8-space-md: 1.25rem;
  --mn8-space-lg: 2rem;
  --mn8-space-xl: 3.25rem;

  /* typography */
  --mn8-text-xl: 1.728rem;
  --mn8-text-lg: 1.25rem;
  --mn8-text-md: 1.2rem;
  --mn8-text-base: 1rem;
  --mn8-text-sm: 0.833rem;
  --mn8-text-xs: 0.694rem;
  --mn8-text-sm: 0.833rem;
}

@media(min-width: 64rem){
  :root {
    /* spacing */
    --mn8-space-4xs: 0.1875rem;
    --mn8-space-3xs: 0.375rem;
    --mn8-space-2xs: 0.5625rem;
    --mn8-space-xs: 0.75rem;
    --mn8-space-sm: 1.125rem;
    --mn8-space-md: 2rem;
    --mn8-space-lg: 3.125rem;
    --mn8-space-xl: 5.125rem;

    /* typography */
    --mn8-text-xl: 2.44rem;
    --mn8-text-lg: 1.75rem;
    --mn8-text-md: 1.5625rem;
    --mn8-text-base: 1.25rem;
    --mn8-text-sm: 1rem;
    --mn8-text-xs: 0.8rem;
    --mn8-text-sm: 1rem;
  }
}

/* buttons */
.mn8-btn {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  white-space: nowrap;
  text-decoration: none;
  background: hsl(var(--mn8-color-bg-dark-hsl));
  color: hsl(var(--mn8-color-contrast-higher-hsl));
  cursor: pointer;
  text-decoration: none;
  line-height: 1.2;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: all 0.2s ease;
  will-change: transform;
  padding: var(--mn8-space-2xs) var(--mn8-space-sm);
  border-radius: 0.25em;
}

.mn8-btn:focus-visible {
  box-shadow: 0px 0px 0px 2px hsl(var(--mn8-color-bg-hsl)), 0px 0px 0px 4px hsla(var(--mn8-color-contrast-higher-hsl), 0.15);
  outline: none;
}

.mn8-btn:active {
  transform: translateY(2px);
}

.mn8-btn--primary {
  background: hsl(var(--mn8-color-primary-hsl));
  color: hsl(var(--mn8-color-white-hsl));
  box-shadow: inset 0px 1px 0px hsla(var(--mn8-color-white-hsl), 0.15), 0px 1px 3px hsla(var(--mn8-color-primary-darker-hsl), 0.25), 0px 2px 6px hsla(var(--mn8-color-primary-darker-hsl), 0.1), 0px 6px 10px -2px hsla(var(--mn8-color-primary-darker-hsl), 0.25);
}

.mn8-btn--primary:hover {
  background: hsl(var(--mn8-color-primary-light-hsl));
  box-shadow: inset 0px 1px 0px hsla(var(--mn8-color-white-hsl), 0.15), 0px 1px 2px hsla(var(--mn8-color-primary-darker-hsl), 0.25), 0px 1px 4px hsla(var(--mn8-color-primary-darker-hsl), 0.1), 0px 3px 6px -2px hsla(var(--mn8-color-primary-darker-hsl), 0.25);
}

.mn8-btn--primary:focus {
  box-shadow: inset 0px 1px 0px hsla(var(--mn8-color-white-hsl), 0.15), 0px 1px 2px hsla(var(--mn8-color-primary-darker-hsl), 0.25), 0px 1px 4px hsla(var(--mn8-color-primary-darker-hsl), 0.1), 0px 3px 6px -2px hsla(var(--mn8-color-primary-darker-hsl), 0.25), 0px 0px 0px 2px hsl(var(--mn8-color-bg-hsl)), 0px 0px 0px 4px hsl(var(--mn8-color-primary-hsl));
}

.mn8-btn--subtle {
  background: hsl(var(--mn8-color-bg-lighter-hsl));
  color: hsl(var(--mn8-color-contrast-higher-hsl));
  box-shadow: inset 0px 1px 0px hsla(var(--mn8-color-white-hsl), 0.1), 0px 0px 0px 1px hsla(var(--mn8-color-black-hsl), 0.02), 0px 1px 3px -1px hsla(var(--mn8-color-black-hsl), 0.2), 0 0.3px 0.4px rgba(0, 0, 0, 0.025),0 0.9px 1.5px rgba(0, 0, 0, 0.05), 0 3.5px 6px rgba(0, 0, 0, 0.1);
}

.mn8-btn--subtle:hover {
  background: hsl(var(--mn8-color-bg-light-hsl));
  box-shadow: inset 0px 1px 0px hsla(var(--mn8-color-white-hsl), 0.1), 0px 0px 0px 1px hsla(var(--mn8-color-black-hsl), 0.02), 0px 1px 3px -1px hsla(var(--mn8-color-black-hsl), 0.2), 0 0.1px 0.3px rgba(0, 0, 0, 0.06),0 1px 2px rgba(0, 0, 0, 0.12);
}

.mn8-btn--subtle:focus {
  box-shadow: inset 0px 1px 0px hsla(var(--mn8-color-white-hsl), 0.1), 0px 0px 0px 1px hsla(var(--mn8-color-black-hsl), 0.02), 0px 1px 3px -1px hsla(var(--mn8-color-black-hsl), 0.2), 0 0.1px 0.3px rgba(0, 0, 0, 0.06),0 1px 2px rgba(0, 0, 0, 0.12), 0px 0px 0px 2px hsl(var(--mn8-color-bg-hsl)), 0px 0px 0px 4px hsl(var(--mn8-color-contrast-high-hsl));
}

/* form elements */
.mn8-form-control {
  font-size: 1em;
  padding: var(--mn8-space-2xs) var(--mn8-space-xs);
  background: hsl(var(--mn8-color-bg-dark-hsl));
  line-height: 1.2;
  box-shadow: inset 0px 0px 0px 1px hsl(var(--mn8-color-contrast-lower-hsl));
  transition: all 0.2s ease;
  border-radius: 0.25em;
}

.mn8-form-control::placeholder {
  opacity: 1;
  color: hsl(var(--mn8-color-contrast-low-hsl));
}

.mn8-form-control:focus, .mn8-form-control:focus-within {
  background: hsl(var(--mn8-color-bg-hsl));
  box-shadow: inset 0px 0px 0px 1px hsla(var(--mn8-color-contrast-lower-hsl), 0), 0px 0px 0px 2px hsl(var(--mn8-color-primary-hsl)), 0 0.3px 0.4px rgba(0, 0, 0, 0.025),0 0.9px 1.5px rgba(0, 0, 0, 0.05), 0 3.5px 6px rgba(0, 0, 0, 0.1);
  outline: none;
}

/* icons */
.mn8-icon {
  height: var(--mn8-size, 1em);
  width: var(--mn8-size, 1em);
  display: inline-block;
  color: inherit;
  fill: currentColor;
  line-height: 1;
  flex-shrink: 0;
  max-width: initial;
}

.mn8-icon--xs {
  --mn8-size: 16px;
}

/* component */
:root {
  --mega-nav-height: 50px;
  --mega-nav-content-max-width: 80rem;
  /* set max-width for navigation content */
}
@media (min-width: 64rem) {
  :root {
    --mega-nav-height: 70px;
  }
}

.mega-nav {
  height: var(--mega-nav-height);
  width: 100%;
  z-index: 3;
  box-shadow: inset 0px -1px 0px hsla(var(--mn8-color-contrast-higher-hsl), 0.1);
  /* border bottom */
}

/* change mega-nav style if menu = expanded */
.mega-nav--expanded {
  background-color: hsl(var(--mn8-color-bg-hsl));
}

.mega-nav__container {
  width: calc(100% - 2 * var(--mn8-space-md));
  height: 100%;
  max-width: var(--mega-nav-content-max-width);
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* logo */
.mega-nav__logo {
  display: block;
  width: 104px;
  /* logo width */
  height: 30px;
  /* logo height */
}
.mega-nav__logo > * {
  /* logo SVG */
  width: inherit;
  height: inherit;
}

/* mobile buttons */
.mega-nav__icon-btns {
  display: flex;
  align-items: center;
}

.mega-nav__icon-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  font-size: 24px;
  /* icon size */
  color: hsl(var(--mn8-color-contrast-high-hsl));
  /* icon color */
  cursor: pointer;
  transition: 0.2s;
}
.mega-nav__icon-btn .mn8-icon {
  display: block;
}
.mega-nav__icon-btn .icon__group {
  stroke-width: 1px;
  /* icon stroke width */
}
.mega-nav__icon-btn .icon__group > * {
  transition: stroke-dashoffset 0.3s, opacity 0.3s, -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), stroke-dashoffset 0.3s, opacity 0.3s;
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), stroke-dashoffset 0.3s, opacity 0.3s, -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

/* animated menu button */
.mega-nav__icon-btn--menu .icon__group > * {
  stroke-dasharray: 24;
}
.mega-nav__icon-btn--menu .icon__group > *:nth-child(1) {
  -webkit-transform-origin: 12px 6px;
          transform-origin: 12px 6px;
}
.mega-nav__icon-btn--menu .icon__group > *:nth-child(2) {
  stroke-dashoffset: 0;
}
.mega-nav__icon-btn--menu .icon__group > *:nth-child(3) {
  -webkit-transform-origin: 12px 18px;
          transform-origin: 12px 18px;
}
.mega-nav__icon-btn--menu.mega-nav__icon-btn--state-b .icon__group > *:nth-child(1) {
  -webkit-transform: translateY(6px) rotate(-45deg);
          transform: translateY(6px) rotate(-45deg);
}
.mega-nav__icon-btn--menu.mega-nav__icon-btn--state-b .icon__group > *:nth-child(2) {
  stroke-dashoffset: 24;
}
.mega-nav__icon-btn--menu.mega-nav__icon-btn--state-b .icon__group > *:nth-child(3) {
  -webkit-transform: translateY(-6px) rotate(45deg);
          transform: translateY(-6px) rotate(45deg);
}

/* animated search button */
.mega-nav__icon-btn--search .icon__group {
  -webkit-transform-origin: 12px 12px;
          transform-origin: 12px 12px;
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  transition: -webkit-transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.mega-nav__icon-btn--search .icon__group > *:nth-child(1) {
  stroke-dashoffset: 34;
  stroke-dasharray: 24;
}
.mega-nav__icon-btn--search .icon__group > *:nth-child(2) {
  stroke-dashoffset: 24;
  stroke-dasharray: 24;
}
.mega-nav__icon-btn--search .icon__group > *:nth-child(3) {
  -webkit-transform-origin: 9.5px 9.5px;
          transform-origin: 9.5px 9.5px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  stroke-dashoffset: 84;
  stroke-dasharray: 42;
  opacity: 1;
}
.mega-nav__icon-btn--search.mega-nav__icon-btn--state-b .icon__group {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}
.mega-nav__icon-btn--search.mega-nav__icon-btn--state-b .icon__group > *:nth-child(1) {
  stroke-dashoffset: 48;
}
.mega-nav__icon-btn--search.mega-nav__icon-btn--state-b .icon__group > *:nth-child(2) {
  stroke-dashoffset: 48;
}
.mega-nav__icon-btn--search.mega-nav__icon-btn--state-b .icon__group > *:nth-child(3) {
  stroke-dashoffset: 42;
  opacity: 0;
}

/* animated arrow icon */
.mega-nav__arrow-icon {
  font-size: 16px;
  /* icon size */
  color: currentColor;
  /* icon color */
  transition: color 0.2s;
}
.mega-nav__arrow-icon .mn8-icon {
  display: block;
}
.mega-nav__arrow-icon .icon__group {
  stroke-width: 1px;
  /* icon stroke width */
  will-change: transform;
  -webkit-transform-origin: 8px 8px;
          transform-origin: 8px 8px;
  transition: -webkit-transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.mega-nav__arrow-icon .icon__group > * {
  stroke-dasharray: 17;
  -webkit-transform-origin: 8px 8px;
          transform-origin: 8px 8px;
  -webkit-transform: translateY(3px);
          transform: translateY(3px);
  transition: stroke-dashoffset 0.3s, -webkit-transform 0.3s;
  transition: transform 0.3s, stroke-dashoffset 0.3s;
  transition: transform 0.3s, stroke-dashoffset 0.3s, -webkit-transform 0.3s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.mega-nav__arrow-icon .icon__group > *:first-child {
  stroke-dashoffset: 8.5;
}
.mega-nav__arrow-icon .icon__group > *:last-child {
  stroke-dashoffset: 8.5;
}

/* label/divider */
.mega-nav__label {
  color: hsl(var(--mn8-color-contrast-medium-hsl));
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-size: var(--mn8-text-xs);
}

/* card */
.mega-nav__card img {
  transition: opacity 0.3s;
}
.mega-nav__card img:hover {
  opacity: 0.85;
}

.mega-nav__card-title {
  color: hsl(var(--mn8-color-contrast-higher-hsl));
  text-decoration: none;
}
.mega-nav__card-title:hover {
  text-decoration: underline;
}

/* -------------------------------- 

--mobile - style affecting only small screens 👇

-------------------------------- */
.mega-nav--mobile {
  /* sub navigation */
}
.mega-nav--mobile .mega-nav__icon-btns--desktop {
  display: none;
  /* hide --desktop icon buttons */
}
.mega-nav--mobile .mega-nav__nav, .mega-nav--mobile .mega-nav__search {
  display: none;
  position: absolute;
  top: var(--mega-nav-height);
  left: 0;
  width: 100%;
  height: calc(100vh - var(--mega-nav-height) - var(--mega-nav-offset-y, 0px));
  /* set --mega-nav-offset-y in JS */
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  background-color: hsl(var(--mn8-color-bg-hsl));
  box-shadow: 0 1.2px 1.9px -1px rgba(0, 0, 0, 0.014),0 3.3px 5.3px -1px rgba(0, 0, 0, 0.038),0 8.5px 12.7px -1px rgba(0, 0, 0, 0.085),0 30px 42px -1px rgba(0, 0, 0, 0.15);
}
.mega-nav--mobile .mega-nav__nav--is-visible, .mega-nav--mobile .mega-nav__search--is-visible {
  display: block;
}
.mega-nav--mobile .mega-nav__nav--is-visible > *, .mega-nav--mobile .mega-nav__search--is-visible > * {
  -webkit-animation: mega-nav-entry-animation 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
          animation: mega-nav-entry-animation 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.mega-nav--mobile .mega-nav__nav-inner, .mega-nav--mobile .mega-nav__search-inner {
  padding: var(--mn8-space-md) 0;
  width: calc(100% - 2 * var(--mn8-space-md));
  max-width: var(--mega-nav-content-max-width);
  margin-left: auto;
  margin-right: auto;
}
.mega-nav--mobile .mega-nav__label {
  /* label/divider */
  margin: var(--mn8-space-lg) 0 var(--mn8-space-xs);
}
.mega-nav--mobile .mega-nav__item {
  /* main navigation items */
  border-bottom: 1px solid hsl(var(--mn8-color-contrast-lower-hsl));
}
.mega-nav--mobile .mega-nav__control {
  /* navigation main controls (buttons/links) */
  display: flex;
  align-items: center;
  width: 100%;
  font-size: var(--mn8-text-md);
  text-align: left;
  padding: var(--mn8-space-sm) 0;
  color: hsl(var(--mn8-color-contrast-higher-hsl));
  text-decoration: none;
  cursor: pointer;
}
.mega-nav--mobile a[aria-current=page] {
  /* current page */
  color: hsl(var(--mn8-color-primary-hsl));
}
.mega-nav--mobile .mega-nav__arrow-icon {
  margin-left: auto;
  margin-right: 12px;
}
.mega-nav--mobile .mega-nav__btn {
  /* button */
  width: 100%;
  margin: var(--mn8-space-sm) 0;
  font-size: var(--mn8-text-md);
}
.mega-nav--mobile .mega-nav__sub-nav-wrapper {
  /* sub navigation content */
  display: none;
  padding: 0 var(--mn8-space-md) var(--mn8-space-lg);
  overflow: hidden;
}
.mega-nav--mobile .mega-nav__sub-items {
  /* list of sub items */
}
.mega-nav--mobile .mega-nav__sub-items:not(:last-child) {
  margin-bottom: var(--mn8-space-lg);
}
.mega-nav--mobile .mega-nav__sub-item {
  /* sub item */
  border-bottom: 1px solid hsl(var(--mn8-color-contrast-lower-hsl));
}
.mega-nav--mobile .mega-nav__sub-link {
  /* sub link */
  display: block;
  color: hsl(var(--mn8-color-contrast-higher-hsl));
  text-decoration: none;
  padding: var(--mn8-space-2xs) 0;
}
.mega-nav--mobile .mega-nav__quick-link {
  /* search quick links */
  display: block;
  color: hsl(var(--mn8-color-contrast-higher-hsl));
  text-decoration: none;
  border-bottom: 1px solid hsl(var(--mn8-color-contrast-lower-hsl));
  padding: var(--mn8-space-xs) 0;
}
.mega-nav--mobile .mega-nav__sub-nav--layout-1 {
  /* layout 1 -> tabbed content */
}
.mega-nav--mobile .mega-nav__sub-nav--layout-1 .mega-nav__sub-items {
  margin-bottom: 0;
}
.mega-nav--mobile .mega-nav__sub-nav--layout-1 .mega-nav__tabs {
  display: none;
  /* hide tabbed content on smaller screens */
}
.mega-nav--mobile .mega-nav__sub-nav--layout-3 {
  /* layout 3 -> gallery */
  padding-top: var(--mn8-space-md);
  display: grid;
  gap: var(--mn8-space-md);
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  /* auto add new cols */
}
.mega-nav--mobile .mega-nav__item--expanded .mega-nav__arrow-icon .icon__group {
  /* animated arrow icon */
}
.mega-nav--mobile .mega-nav__item--expanded .mega-nav__arrow-icon .icon__group > *:first-child {
  -webkit-transform: translateY(-3px) rotate(-90deg);
          transform: translateY(-3px) rotate(-90deg);
}
.mega-nav--mobile .mega-nav__item--expanded .mega-nav__arrow-icon .icon__group > *:last-child {
  -webkit-transform: translateY(-3px) rotate(90deg);
          transform: translateY(-3px) rotate(90deg);
}
.mega-nav--mobile .mega-nav__item--expanded .mega-nav__sub-nav-wrapper {
  display: block;
}
.mega-nav--mobile .mega-nav__item--expanded .mega-nav__sub-nav-wrapper > * {
  -webkit-animation: mega-nav-entry-animation 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
          animation: mega-nav-entry-animation 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/* -------------------------------- 

--desktop - style affecting only big screens 👇

-------------------------------- */
.mega-nav--desktop {
  /* tabs */
  /* icon animation on click */
  /* icon buttons */
}
.mega-nav--desktop .mega-nav__icon-btns--mobile, .mega-nav--desktop .mega-nav__sub-nav-wrapper, .mega-nav--desktop .mega-nav__search, .mega-nav--desktop .mega-nav__label {
  display: none;
}
.mega-nav--desktop .mega-nav__logo {
  flex-shrink: 0;
  margin-right: var(--mn8-space-sm);
}
.mega-nav--desktop .mega-nav__nav {
  flex-grow: 1;
  height: 100%;
}
.mega-nav--desktop .mega-nav__nav-inner {
  height: 100%;
  display: flex;
  justify-content: space-between;
  /* navigation layout - change to "flex-end" to push the navigation to the right */
}
.mega-nav--desktop .mega-nav__items {
  display: flex;
  height: 100%;
}
.mega-nav--desktop .mega-nav__item {
  display: flex;
  align-items: center;
}
.mega-nav--desktop .mega-nav__control {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 var(--mn8-space-md);
  height: 100%;
  font-size: var(--mn8-text-sm);
  color: hsl(var(--mn8-color-contrast-higher-hsl));
  text-decoration: none;
  transition: 0.2s;
}
.mega-nav--desktop .mega-nav__control::after {
  /* marker */
  content: "";
  position: absolute;
  bottom: 0px;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: hsl(var(--mn8-color-contrast-higher-hsl));
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.2s;
}
.mega-nav--desktop .mega-nav__control .mega-nav__arrow-icon {
  margin-left: var(--mn8-space-2xs);
}
.mega-nav--desktop .mega-nav__control:hover {
  cursor: pointer;
  background-color: hsla(var(--mn8-color-contrast-higher-hsl), 0.05);
}
.mega-nav--desktop .mega-nav__sub-nav-wrapper, .mega-nav--desktop .mega-nav__search {
  position: absolute;
  top: var(--mega-nav-height);
  left: 0;
  width: 100%;
  background-color: hsl(var(--mn8-color-bg-hsl));
  box-shadow: 0 1.2px 1.9px -1px rgba(0, 0, 0, 0.014),0 3.3px 5.3px -1px rgba(0, 0, 0, 0.038),0 8.5px 12.7px -1px rgba(0, 0, 0, 0.085),0 30px 42px -1px rgba(0, 0, 0, 0.15);
  max-height: calc(100vh - var(--mega-nav-height) - var(--mega-nav-offset-y, 0px));
  /* set --mega-nav-offset-y in JS */
  overflow: auto;
}
.mega-nav--desktop .mega-nav__sub-nav, .mega-nav--desktop .mega-nav__search-inner {
  width: calc(100% - 2 * var(--mn8-space-md));
  margin: 0 auto;
  padding: var(--mn8-space-xl) 0;
}
.mega-nav--desktop .mega-nav__sub-nav .mega-nav__label, .mega-nav--desktop .mega-nav__search-inner .mega-nav__label {
  display: block;
}
.mega-nav--desktop .mega-nav__sub-nav {
  max-width: var(--mega-nav-content-max-width);
}
.mega-nav--desktop .mega-nav__search-inner {
  max-width: 38rem;
  /* reduce max-width for search content */
}
.mega-nav--desktop .mega-nav__label {
  margin-bottom: var(--mn8-space-md);
}
.mega-nav--desktop .mega-nav__sub-item:not(:last-child) {
  margin-bottom: var(--mn8-space-2xs);
}
.mega-nav--desktop .mega-nav__sub-link {
  font-size: var(--mn8-text-sm);
  color: hsl(var(--mn8-color-contrast-higher-hsl));
  text-decoration: none;
}
.mega-nav--desktop .mega-nav__sub-link:hover {
  text-decoration: underline;
}
.mega-nav--desktop .mega-nav__quick-link {
  /* search quick links */
  display: inline-block;
  margin-bottom: var(--mn8-space-2xs);
  font-size: var(--mn8-text-sm);
  color: hsl(var(--mn8-color-contrast-higher-hsl));
  text-decoration: none;
}
.mega-nav--desktop .mega-nav__quick-link:hover {
  text-decoration: underline;
}
.mega-nav--desktop .mega-nav__btn {
  font-size: var(--mn8-text-sm);
  margin-left: var(--mn8-space-sm);
}
.mega-nav--desktop .mega-nav__sub-nav--layout-1 {
  /* layout 1 -> tabbed content */
}
.mega-nav--desktop .mega-nav__sub-nav--layout-1 .mega-nav__sub-items {
  display: none;
  /* hide links */
}
.mega-nav--desktop .mega-nav__sub-nav--layout-2 {
  /* layout 2 -> multi lists */
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: var(--mn8-space-sm);
}
.mega-nav--desktop .mega-nav__sub-nav--layout-3 {
  /* layout 3 -> gallery */
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: var(--mn8-space-md);
}
.mega-nav--desktop .mega-nav__sub-nav--layout-4 {
  /* layout 4 -> single list */
  text-align: center;
}
.mega-nav--desktop .mega-nav__sub-nav--layout-4 .mega-nav__sub-link {
  font-size: var(--mn8-text-lg);
}
.mega-nav--desktop .mega-nav__tabs-controls > *:not(:last-child) {
  border-bottom: 1px solid hsl(var(--mn8-color-contrast-lower-hsl));
}
.mega-nav--desktop .mega-nav__tabs-control {
  display: block;
  width: 100%;
  padding: var(--mn8-space-xs);
  overflow: hidden;
  color: hsl(var(--mn8-color-contrast-higher-hsl));
  text-decoration: none;
  cursor: pointer;
  transition: 0.2s;
}
.mega-nav--desktop .mega-nav__tabs-control .mn8-icon {
  opacity: 0;
  -webkit-transform: translateX(-10px);
          transform: translateX(-10px);
  transition: opacity 0.5s, -webkit-transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 0.5s;
  transition: transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 0.5s, -webkit-transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.mega-nav--desktop .mega-nav__tabs-control:hover, .mega-nav--desktop .mega-nav__tabs-control[aria-selected=true] {
  color: hsl(var(--mn8-color-primary-hsl));
}
.mega-nav--desktop .mega-nav__tabs-control[aria-selected=true] .mn8-icon {
  opacity: 1;
  -webkit-transform: translateX(0px);
          transform: translateX(0px);
}
.mega-nav--desktop .mega-nav__tabs-img {
  display: block;
  overflow: hidden;
  border-radius: 0.5em;
  transition: opacity 0.3s;
}
.mega-nav--desktop .mega-nav__tabs-img:hover {
  opacity: 0.85;
}
.mega-nav--desktop .mega-nav__item--expanded .mega-nav__control {
  background-color: hsla(var(--mn8-color-contrast-higher-hsl), 0.05);
}
.mega-nav--desktop .mega-nav__item--expanded .mega-nav__control::after {
  /* marker */
  opacity: 1;
}
.mega-nav--desktop .mega-nav__item--expanded .mega-nav__sub-nav-wrapper {
  display: block;
}
.mega-nav--desktop .mega-nav__item--expanded .mega-nav__sub-nav {
  -webkit-animation: mega-nav-entry-animation 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
          animation: mega-nav-entry-animation 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.mega-nav--desktop:not([data-hover=on]) .mega-nav__item--expanded .mega-nav__arrow-icon .icon__group {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}
.mega-nav--desktop:not([data-hover=on]) .mega-nav__item--expanded .mega-nav__arrow-icon .icon__group > *:first-child, .mega-nav--desktop:not([data-hover=on]) .mega-nav__item--expanded .mega-nav__arrow-icon .icon__group *:last-child {
  stroke-dashoffset: 0;
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
}
.mega-nav--desktop .mega-nav__icon-btn {
  border-radius: 50%;
  margin-left: var(--mn8-space-4xs);
}
.mega-nav--desktop .mega-nav__icon-btn:hover,
.mega-nav--desktop .mega-nav__icon-btn--state-b {
  background-color: hsla(var(--mn8-color-contrast-higher-hsl), 0.05);
}
.mega-nav--desktop .mega-nav__search--is-visible {
  display: block;
}
.mega-nav--desktop .mega-nav__search--is-visible .mega-nav__search-inner {
  -webkit-animation: mega-nav-entry-animation 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
          animation: mega-nav-entry-animation 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/* animations */
@-webkit-keyframes mega-nav-entry-animation {
  from {
    opacity: 0;
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}
@keyframes mega-nav-entry-animation {
  from {
    opacity: 0;
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}
/* used in JS - detect when the menu needs to switch from --mobile to --desktop layout */
[class*=mega-nav--desktop]::before {
  display: none;
  content: "mobile";
}

@media (min-width: 32rem) {
  .mega-nav--desktop\@xs::before {
    content: "desktop";
  }
}
@media (min-width: 48rem) {
  .mega-nav--desktop\@sm::before {
    content: "desktop";
  }
}
@media (min-width: 64rem) {
  .mega-nav--desktop\@md::before {
    content: "desktop";
  }
}
@media (min-width: 80rem) {
  .mega-nav--desktop\@lg::before {
    content: "desktop";
  }
}
@media (min-width: 90rem) {
  .mega-nav--desktop\@xl::before {
    content: "desktop";
  }
}

/* utility classes */
.mn8-margin-top-lg {
  margin-top: var(--mn8-space-lg);
}

.mn8-width-100\% {
  width: 100%;
}

.mn8-flex-center {
  justify-content: center;
  align-items: center;
}

.mn8-height-100\% {
  height: 100%;
}

.mn8-flex {
  display: flex;
}

.mn8-color-inherit {
  color: inherit;
}

.mn8-inline-block {
  display: inline-block;
}

.mn8-text-base {
  font-size: var(--mn8-text-base);
}

.mn8-margin-top-sm {
  margin-top: var(--mn8-space-sm);
}

.mn8-block {
  display: block;
}

:where(.mn8-media-wrapper-4\:3) {
  position: relative;
  height: 0;
}

.mn8-media-wrapper-4\:3 {
  padding-bottom: 75%;
}

.mn8-media-wrapper-4\:3 > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.mn8-media-wrapper-4\:3 > *:not(iframe) {
  object-fit: cover;
}

.mn8-overflow-hidden {
  overflow: hidden;
}

.mn8-radius-lg {
  border-radius: 0.5em;
}

.mn8-margin-x-auto {
  margin-left: auto;
  margin-right: auto;
}

.mn8-max-width-xs {
  max-width: 38rem;
}

.mn8-gap-2xs {
  gap: var(--mn8-space-2xs);
}

.mn8-color-contrast-medium {
  --mn8-color-o: 1;
  color: hsla(var(--mn8-color-contrast-medium-hsl), var(--mn8-color-o, 1));
}

.mn8-text-xl {
  font-size: var(--mn8-text-xl);
}

.mn8-text-component :where(h1, h2, h3, h4) {
  line-height: var(--mn8-heading-line-height, 1.2);
  margin-top: calc(var(--mn8-space-md) * var(--mn8-space-multiplier, 1));
  margin-bottom: calc(var(--mn8-space-sm) * var(--mn8-space-multiplier, 1));
}

.mn8-text-component :where(p, blockquote, ul li, ol li) {
  line-height: var(--mn8-body-line-height, 1.4);
}

.mn8-text-component :where(ul, ol, p, blockquote, .mn8-text-component__block) {
  margin-bottom: calc(var(--mn8-space-sm) * var(--mn8-space-multiplier, 1));
}

.mn8-text-component :where(ul, ol) {
  padding-left: 1.25em;
}

.mn8-text-component ul :where(ul, ol), .mn8-text-component ol :where(ul, ol) {
  padding-left: 1em;
  margin-bottom: 0;
}

.mn8-text-component ul {
  list-style-type: disc;
}

.mn8-text-component ol {
  list-style-type: decimal;
}

.mn8-text-component img {
  display: block;
  margin: 0 auto;
}

.mn8-text-component figcaption {
  margin-top: calc(var(--mn8-space-xs) * var(--mn8-space-multiplier, 1));
  font-size: var(--mn8-text-sm);
  text-align: center;}

.mn8-text-component em {
  font-style: italic;
}

.mn8-text-component strong {
  font-weight: bold;
}

.mn8-text-component s {
  text-decoration: line-through;
}

.mn8-text-component u {
  text-decoration: underline;
}

.mn8-text-component mark {
  background-color: hsla(var(--mn8-color-accent-hsl), 0.2);
  color: inherit;
}

.mn8-text-component blockquote {
  padding-left: 1em;
  border-left: 4px solid hsl(var(--mn8-color-contrast-lower-hsl));
  font-style: italic;
}

.mn8-text-component hr {
  margin: calc(var(--mn8-space-md) * var(--mn8-space-multiplier, 1)) auto;
  background: hsl(var(--mn8-color-contrast-lower-hsl));
  height: 1px;
}

.mn8-text-component > *:first-child {
  margin-top: 0;
}

.mn8-text-component > *:last-child {
  margin-bottom: 0;
}

.mn8-text-component.mn8-line-height-xs {
  --mn8-heading-line-height: 1;
  --mn8-body-line-height: 1.1;
}

.mn8-text-component.mn8-line-height-sm {
  --mn8-heading-line-height: 1.1;
  --mn8-body-line-height: 1.2;
}

.mn8-text-component.mn8-line-height-md {
  --mn8-heading-line-height: 1.15;
  --mn8-body-line-height: 1.4;
}

.mn8-text-component.mn8-line-height-lg {
  --mn8-heading-line-height: 1.22;
  --mn8-body-line-height: 1.58;
}

.mn8-text-component.mn8-line-height-xl {
  --mn8-heading-line-height: 1.3;
  --mn8-body-line-height: 1.72;
}

.mn8-margin-bottom-md {
  margin-bottom: var(--mn8-space-md);
}

.mn8-margin-left-auto {
  margin-left: auto;
}

.mn8-margin-right-3xs {
  margin-right: var(--mn8-space-3xs);
}

.mn8-object-cover {
  object-fit: cover;
}

.mn8-radius-50\% {
  border-radius: 50%;
}

.mn8-height-lg {
  height: 3rem;
}

.mn8-width-lg {
  width: 3rem;
}

.mn8-gap-xs {
  gap: var(--mn8-space-xs);
}

.mn8-items-center {
  align-items: center;
}

.mn8-gap-lg {
  gap: var(--mn8-space-lg);
}

.mn8-grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
}

.mn8-grid > * {
  min-width: 0;
  grid-column-end: span 12;
}

.mn8-position-relative {
  position: relative;
}

.mn8-col-4 {
  grid-column-end: span 4;
}

.mn8-col-8 {
  grid-column-end: span 8;
}