.mv--tg {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
	gap: 1rem;
}

.team {
	// position: relative;
	width: 100%;
	text-align: center;
	// max-width: 50%;
	margin-bottom: 24px;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.09);
}

.team .team-img {
	position: relative;
	font-size: 0;
	color: #353535;
}

.team .team-img img {
	width: 100%;
	height: auto;
}

.team .team-content {
	padding: 20px;
}

.team .team-content h2 {
	margin: 0 0 5px 0;
	color: #353535;
	font-size: 25px;
	font-weight: 600;
	letter-spacing: 2px;
}

.team .team-content h3 {
	margin: 0 0 15px 0;
	color: #353535;
	font-size: 14px;
	font-weight: 300;
}

.team .team-content p {
	margin: 0 0 15px 0;
	color: #353535;
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
}