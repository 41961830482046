/* -------------------------------- 

File#: _1_list
Title: List
Descr: Custom list component
Usage: codyhouse.co/license

-------------------------------- */
/* reset */
*, *::after, *::before {
  box-sizing: border-box;
}

* {
  font: inherit;
  margin: 0;
  padding: 0;
  border: 0;
}

body {
  background-color: hsl(0, 0%, 100%);
  font-family: system-ui, sans-serif;
  color: hsl(230, 7%, 23%);
  font-size: 1rem;
}

h1, h2, h3, h4 {
  line-height: 1.2;
  color: hsl(230, 13%, 9%);
  font-weight: 700;
}

h1 {
  font-size: 2.0736rem;
}

h2 {
  font-size: 1.728rem;
}

h3 {
  font-size: 1.25rem;
}

h4 {
  font-size: 1.2rem;
}

ol, ul, menu {
  list-style: none;
}

button, input, textarea, select {
  background-color: transparent;
  border-radius: 0;
  color: inherit;
  line-height: inherit;
  appearance: none;
}

textarea {
  resize: vertical;
  overflow: auto;
  vertical-align: top;
}

a {
  color: hsl(250, 84%, 54%);
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

img, video, svg {
  display: block;
  max-width: 100%;
}

@media (min-width: 64rem) {
  body {
    font-size: 1.25rem;
  }

  h1 {
    font-size: 3.051rem;
  }

    h2 {
    font-size: 2.44rem;
  }

    h3 {
    font-size: 1.75rem;
  }

    h4 {
    font-size: 1.5625rem;
  }
}

/* variables */
:root {
  /* colors */
  --ls4-color-primary-hsl: 250, 84%, 54%;
  --ls4-color-bg-hsl: 0, 0%, 100%;
  --ls4-color-contrast-high-hsl: 230, 7%, 23%;
  --ls4-color-contrast-higher-hsl: 230, 13%, 9%;
  --ls4-color-accent-hsl: 342, 89%, 48%;
  --ls4-color-contrast-lower-hsl: 240, 4%, 85%;
}

/* icons */
.ls4-icon {
  height: var(--ls4-size, 1em);
  width: var(--ls4-size, 1em);
  display: inline-block;
  color: inherit;
  fill: currentColor;
  line-height: 1;
  flex-shrink: 0;
  max-width: initial;
}

/* component */
:root {
  --list-space-y: 0.375em;
  --list-offset: 1em;
  --list-line-height-multiplier: 1;
}

.list, [class*="text-component"] .list {
  padding-left: 0;
  list-style: none;
}
.list ul, .list ol, [class*="text-component"] .list ul, [class*="text-component"] .list ol {
  list-style: none;
  margin: 0;
  margin-top: calc((var(--list-space-y) / 2) * var(--text-space-y-multiplier, 1));
  padding-top: calc((var(--list-space-y) / 2) * var(--text-space-y-multiplier, 1));
  padding-left: var(--list-offset);
}
.list li, [class*="text-component"] .list li {
  padding-bottom: calc((var(--list-space-y) / 2) * var(--text-space-y-multiplier, 1));
  margin-bottom: calc((var(--list-space-y) / 2) * var(--text-space-y-multiplier, 1));
  line-height: calc(var(--ls4-body-line-height, 1.4) * var(--list-line-height-multiplier));
}
.list > li:last-child, .list ul > li:last-child, .list ol > li:last-child, [class*="text-component"] .list > li:last-child, [class*="text-component"] .list ul > li:last-child, [class*="text-component"] .list ol > li:last-child {
  margin-bottom: 0;
}
.list:not(.list--border) > li:last-child, .list ul > li:last-child, .list ol > li:last-child, [class*="text-component"] .list:not(.list--border) > li:last-child, [class*="text-component"] .list ul > li:last-child, [class*="text-component"] .list ol > li:last-child {
  padding-bottom: 0;
}

/* #region (ul + ol) */
.list--ul, [class*="text-component"] .list--ul,
.list--ol, [class*="text-component"] .list--ol {
  --list-offset: calc(var(--list-bullet-size) + var(--list-bullet-margin-right));
}
.list--ul ul, .list--ul ol, [class*="text-component"] .list--ul ul, [class*="text-component"] .list--ul ol,
.list--ol ul,
.list--ol ol, [class*="text-component"] .list--ol ul, [class*="text-component"] .list--ol ol {
  padding-left: 0;
}
@supports (--css: variables) {
  .list--ul li, [class*="text-component"] .list--ul li,
.list--ol li, [class*="text-component"] .list--ol li {
    padding-left: var(--list-offset) !important;
  }
}
.list--ul li::before, [class*="text-component"] .list--ul li::before,
.list--ol li::before, [class*="text-component"] .list--ol li::before {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  position: relative;
  top: -0.1em;
}
@supports (--css: variables) {
  .list--ul li::before, [class*="text-component"] .list--ul li::before,
.list--ol li::before, [class*="text-component"] .list--ol li::before {
    width: var(--list-bullet-size) !important;
    height: var(--list-bullet-size) !important;
    margin-left: calc(var(--list-bullet-size) * -1) !important;
    left: calc(var(--list-bullet-margin-right) * -1) !important;
  }
}

.list--ul, [class*="text-component"] .list--ul {
  --list-bullet-size: 7px;
  --list-bullet-margin-right: 12px;
}
.list--ul > li, [class*="text-component"] .list--ul > li {
  padding-left: 19px;
}
.list--ul > li::before, [class*="text-component"] .list--ul > li::before {
  content: "";
  border-radius: 50%;
  color: hsl(var(--ls4-color-contrast-lower-hsl));
  background-color: currentColor;
  width: 7px;
  height: 7px;
  margin-left: -7px;
  left: -12px;
}
.list--ul ul li::before, [class*="text-component"] .list--ul ul li::before {
  background-color: transparent;
  box-shadow: inset 0 0 0 2px currentColor;
}

.list--ol, [class*="text-component"] .list--ol {
  --list-bullet-size: 26px;
  --list-bullet-margin-right: 6px;
  --list-bullet-font-size: 14px;
  counter-reset: list-items;
}
.list--ol > li, [class*="text-component"] .list--ol > li {
  counter-increment: list-items;
  padding-left: 32px;
}
.list--ol ol, [class*="text-component"] .list--ol ol {
  counter-reset: list-items;
}
.list--ol > li::before, [class*="text-component"] .list--ol > li::before {
  content: counter(list-items);
  font-size: var(--list-bullet-font-size, 14px);
  background-color: hsl(var(--ls4-color-contrast-lower-hsl));
  color: hsl(var(--ls4-color-contrast-high-hsl));
  line-height: 1;
  border-radius: 50%;
  width: 26px;
  height: 26px;
  margin-left: -26px;
  left: -6px;
}
.list--ol ol > li::before, [class*="text-component"] .list--ol ol > li::before {
  background-color: transparent;
  box-shadow: inset 0 0 0 2px hsl(var(--ls4-color-contrast-lower-hsl));
}

/* #endregion */
/* #region (border) */
.list--border li:not(:last-child), [class*="text-component"] .list--border li:not(:last-child) {
  border-bottom: 1px solid hsl(var(--ls4-color-contrast-lower-hsl));
}
.list--border ul, .list--border ol, [class*="text-component"] .list--border ul, [class*="text-component"] .list--border ol {
  border-top: 1px solid hsl(var(--ls4-color-contrast-lower-hsl));
}

/* #endregion */
/* #region (icons) */
.list--icons, [class*="text-component"] .list--icons {
  --list-bullet-size: 24px;
  --list-bullet-margin-right: 8px;
  --list-offset: calc(var(--list-bullet-size) + var(--list-bullet-margin-right));
}
.list--icons ul, .list--icons ol, [class*="text-component"] .list--icons ul, [class*="text-component"] .list--icons ol {
  padding-left: 32px;
}
@supports (--css: variables) {
  .list--icons ul, .list--icons ol, [class*="text-component"] .list--icons ul, [class*="text-component"] .list--icons ol {
    padding-left: var(--list-offset);
  }
}

.list .list__icon {
  position: relative;
  width: 24px;
  height: 24px;
  margin-right: 8px;
}
.list .list__icon:not(.ls4-top-0) {
  top: calc((1em * var(--ls4-body-line-height, 1.4) - 24px) / 2);
}
@supports (--css: variables) {
  .list .list__icon {
    width: var(--list-bullet-size);
    height: var(--list-bullet-size);
    margin-right: var(--list-bullet-margin-right);
  }
  .list .list__icon:not(.ls4-top-0) {
    top: calc((1em * var(--ls4-body-line-height, 1.4) * var(--list-line-height-multiplier) - var(--list-bullet-size)) / 2);
  }
}

/* #endregion */

/* utility classes */
.ls4-items-start {
  align-items: flex-start;
}

.ls4-flex {
  display: flex;
}

.ls4-color-primary {
  --ls4-color-o: 1;
  color: hsla(var(--ls4-color-primary-hsl), var(--ls4-color-o, 1));
}