/* -------------------------------- 

File#: _2_anim-cards
Title: Animated Cards
Descr: A collection of cards that slides over time
Usage: codyhouse.co/license

-------------------------------- */
/* variables */
:root {
  /* colors */
  --av8-color-primary-hsl: 250, 84%, 54%;
  --av8-color-bg-hsl: 0, 0%, 100%;
  --av8-color-contrast-high-hsl: 230, 7%, 23%;
  --av8-color-contrast-higher-hsl: 230, 13%, 9%;

  /* spacing */
  --av8-space-3xs: 0.25rem;
  --av8-space-sm: 0.75rem;
  --av8-space-md: 1.25rem;

  /* typography */
  --av8-text-md: 1.2rem;
  --av8-text-base: 1rem;
  --av8-text-sm: 0.833rem;
}

@media(min-width: 64rem){
  :root {
    /* spacing */
    --av8-space-3xs: 0.375rem;
    --av8-space-sm: 1.125rem;
    --av8-space-md: 2rem;

    /* typography */
    --av8-text-md: 1.5625rem;
    --av8-text-base: 1.25rem;
    --av8-text-sm: 1rem;
  }
}

/* component */
:root {
  --anim-cards-width: 280px;
  --anim-cards-gap: var(--av8-space-md);
}

@supports (--css: variables) {
  @media (min-width: 48rem) {
    :root {
      --anim-cards-width: 400px;
    }
  }
  @media (min-width: 64rem) {
    :root {
      --anim-cards-width: 550px;
    }
  }
}
.anim-cards {
  overflow: hidden;
  margin-top: var(--anim-cards-gap);
  margin-bottom: var(--anim-cards-gap);
}

.anim-cards__list {
  display: flex;
  overflow: auto;
}

.anim-cards__item {
  float: left;
  width: var(--anim-cards-width);
  flex-shrink: 0;
  margin-right: var(--anim-cards-gap);
}

.anim-cards-control {
  display: inline-flex;
  width: 40px;
  height: 40px;
  background-color: hsla(var(--av8-color-contrast-higher-hsl), 0.75);
  border-radius: 50%;
  transition: 0.2s;
}
.anim-cards-control i {
  position: relative;
  display: block;
  width: 16px;
  height: 16px;
  margin: auto;
  color: hsl(var(--av8-color-bg-hsl));
}
.anim-cards-control i::before, .anim-cards-control i::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: currentColor;
  transition: -webkit-clip-path 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: clip-path 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: clip-path 0.2s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-clip-path 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.anim-cards-control i::before {
  -webkit-clip-path: polygon(1px 1px, 5px 1px, 5px calc(100% - 1px), 1px calc(100% - 1px));
          clip-path: polygon(1px 1px, 5px 1px, 5px calc(100% - 1px), 1px calc(100% - 1px));
}
.anim-cards-control i::after {
  -webkit-clip-path: polygon(calc(100% - 5px) 1px, calc(100% - 1px) 1px, calc(100% - 1px) calc(100% - 1px), calc(100% - 5px) calc(100% - 1px));
          clip-path: polygon(calc(100% - 5px) 1px, calc(100% - 1px) 1px, calc(100% - 1px) calc(100% - 1px), calc(100% - 5px) calc(100% - 1px));
}
.anim-cards-control:hover {
  cursor: pointer;
  background-color: hsla(var(--av8-color-contrast-higher-hsl), 0.95);
}
.anim-cards-control[aria-pressed=true] i::before {
  -webkit-clip-path: polygon(2px 0%, 100% 50%, 100% 50%, 2px 100%);
          clip-path: polygon(2px 0%, 100% 50%, 100% 50%, 2px 100%);
}
.anim-cards-control[aria-pressed=true] i::after {
  -webkit-clip-path: polygon(calc(100% - 5px) calc(100% - 1px), calc(100% - 1px) calc(100% - 1px), calc(100% - 1px) calc(100% - 1px), calc(100% - 5px) calc(100% - 1px));
          clip-path: polygon(calc(100% - 5px) calc(100% - 1px), calc(100% - 1px) calc(100% - 1px), calc(100% - 1px) calc(100% - 1px), calc(100% - 5px) calc(100% - 1px));
}

.anim-cards:not(.anim-cards--anim-off) .anim-cards__list {
  overflow: visible;
  will-change: transform;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}

.anim-cards--reverse:not(.anim-cards--anim-off) .anim-cards__list {
  flex-direction: row-reverse;
}

/* utility classes */
.av8-opacity-70\% {
  opacity: 0.7;
}

.av8-text-sm {
  font-size: var(--av8-text-sm);
}

.av8-margin-top-3xs {
  margin-top: var(--av8-space-3xs);
}

.av8-text-md {
  font-size: var(--av8-text-md);
}

.av8-padding-bottom-sm {
  padding-bottom: var(--av8-space-sm);
}

.av8-padding-top-md {
  padding-top: var(--av8-space-md);
}

.av8-padding-x-sm {
  padding-left: var(--av8-space-sm);
  padding-right: var(--av8-space-sm);
}

.av8-radius-md {
  border-radius: 0.25em;
}

.av8-text-right {
  text-align: right;
}

.av8-max-width-lg {
  max-width: 80rem;
}

.av8-container {
  width: calc(100% - 2*var(--av8-space-md));
  margin-left: auto;
  margin-right: auto;
}

.av8-hide {
  display: none !important;
}

@media(min-width: 64rem){
  .av8-text-base\@md {
    font-size: var(--av8-text-base);
  }
}