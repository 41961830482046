/* -------------------------------- 

File#: _1_main-footer-v5
Title: Main Footer v5
Descr: Footer navigation template
Usage: codyhouse.co/license

-------------------------------- */
/* variables */
:root {
    /* colors */
    --mu0-color-primary-hsl: 250, 84%, 54%;
    --mu0-color-bg-hsl: 0, 0%, 100%;
    --mu0-color-contrast-high-hsl: 230, 7%, 23%;
    --mu0-color-contrast-higher-hsl: 230, 13%, 9%;
    --mu0-color-contrast-medium-hsl: 225, 4%, 47%;
    --mu0-color-bg-dark-hsl: 240, 4%, 95%;
    --mu0-color-white-hsl: 0, 0%, 100%;
    --mu0-color-primary-darker-hsl: 250, 84%, 38%;
    --mu0-color-primary-light-hsl: 250, 84%, 60%;
    --mu0-color-contrast-lower-hsl: 240, 4%, 85%;
    --mu0-color-contrast-low-hsl: 240, 4%, 65%;
    --mu0-color-accent-hsl: 342, 89%, 48%;

    /* spacing */
    --mu0-space-3xs: 0.25rem;
    --mu0-space-2xs: 0.375rem;
    --mu0-space-xs: 0.5rem;
    --mu0-space-sm: 0.75rem;
    --mu0-space-md: 1.25rem;
    --mu0-space-lg: 2rem;
    --mu0-space-xl: 3.25rem;

    /* typography */
    --mu0-text-base: 1rem;
    --mu0-text-sm: 0.833rem;
    --mu0-text-xs: 0.694rem;
    --mu0-text-sm: 0.833rem;
}

@media(min-width: 64rem) {
    :root {
        /* spacing */
        --mu0-space-3xs: 0.375rem;
        --mu0-space-2xs: 0.5625rem;
        --mu0-space-xs: 0.75rem;
        --mu0-space-sm: 1.125rem;
        --mu0-space-md: 2rem;
        --mu0-space-lg: 3.125rem;
        --mu0-space-xl: 5.125rem;

        /* typography */
        --mu0-text-base: 1.25rem;
        --mu0-text-sm: 1rem;
        --mu0-text-xs: 0.8rem;
        --mu0-text-sm: 1rem;
    }
}

/* buttons */
.mu0-btn {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 1em;
    white-space: nowrap;
    text-decoration: none;
    background: hsl(var(--mu0-color-bg-dark-hsl));
    color: hsl(var(--mu0-color-contrast-higher-hsl));
    cursor: pointer;
    text-decoration: none;
    line-height: 1.2;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transition: all 0.2s ease;
    will-change: transform;
    padding: var(--mu0-space-2xs) var(--mu0-space-sm);
    border-radius: 0.25em;
}

.mu0-btn:focus-visible {
    box-shadow: 0px 0px 0px 2px hsl(var(--mu0-color-bg-hsl)), 0px 0px 0px 4px hsla(var(--mu0-color-contrast-higher-hsl), 0.15);
    outline: none;
}

.mu0-btn:active {
    transform: translateY(2px);
}

.mu0-btn--primary {
    background: hsl(var(--mu0-color-primary-hsl));
    color: hsl(var(--mu0-color-white-hsl));
    box-shadow: inset 0px 1px 0px hsla(var(--mu0-color-white-hsl), 0.15), 0px 1px 3px hsla(var(--mu0-color-primary-darker-hsl), 0.25), 0px 2px 6px hsla(var(--mu0-color-primary-darker-hsl), 0.1), 0px 6px 10px -2px hsla(var(--mu0-color-primary-darker-hsl), 0.25);
}

.mu0-btn--primary:hover {
    background: hsl(var(--mu0-color-primary-light-hsl));
    box-shadow: inset 0px 1px 0px hsla(var(--mu0-color-white-hsl), 0.15), 0px 1px 2px hsla(var(--mu0-color-primary-darker-hsl), 0.25), 0px 1px 4px hsla(var(--mu0-color-primary-darker-hsl), 0.1), 0px 3px 6px -2px hsla(var(--mu0-color-primary-darker-hsl), 0.25);
}

.mu0-btn--primary:focus {
    box-shadow: inset 0px 1px 0px hsla(var(--mu0-color-white-hsl), 0.15), 0px 1px 2px hsla(var(--mu0-color-primary-darker-hsl), 0.25), 0px 1px 4px hsla(var(--mu0-color-primary-darker-hsl), 0.1), 0px 3px 6px -2px hsla(var(--mu0-color-primary-darker-hsl), 0.25), 0px 0px 0px 2px hsl(var(--mu0-color-bg-hsl)), 0px 0px 0px 4px hsl(var(--mu0-color-primary-hsl));
}

/* form elements */
.mu0-form-control {
    font-size: 1em;
    padding: var(--mu0-space-2xs) var(--mu0-space-xs);
    background: hsl(var(--mu0-color-bg-dark-hsl));
    line-height: 1.2;
    box-shadow: inset 0px 0px 0px 1px hsl(var(--mu0-color-contrast-lower-hsl));
    transition: all 0.2s ease;
    border-radius: 0.25em;
}

.mu0-form-control::placeholder {
    opacity: 1;
    color: hsl(var(--mu0-color-contrast-low-hsl));
}

.mu0-form-control:focus,
.mu0-form-control:focus-within {
    background: hsl(var(--mu0-color-bg-hsl));
    box-shadow: inset 0px 0px 0px 1px hsla(var(--mu0-color-contrast-lower-hsl), 0), 0px 0px 0px 2px hsl(var(--mu0-color-primary-hsl)), 0 0.3px 0.4px rgba(0, 0, 0, 0.025), 0 0.9px 1.5px rgba(0, 0, 0, 0.05), 0 3.5px 6px rgba(0, 0, 0, 0.1);
    outline: none;
}

/* icons */
.mu0-icon {
    height: var(--mu0-size, 1em);
    width: var(--mu0-size, 1em);
    display: inline-block;
    color: inherit;
    fill: currentColor;
    line-height: 1;
    flex-shrink: 0;
    max-width: initial;
}

/* component */
.footer-v5 {
    position: relative;
    z-index: 1;
}

.footer-v5__popular-link {
    display: inline-block;
    padding: var(--mu0-space-2xs);
    background-color: hsla(var(--mu0-color-contrast-higher-hsl), 0.1);
    border-radius: 0.25em;
    color: hsl(var(--mu0-color-contrast-medium-hsl));
    line-height: 1;
    transition: 0.2s;
}

.footer-v5__popular-link:hover {
    color: hsl(var(--mu0-color-contrast-high-hsl));
    background-color: hsla(var(--mu0-color-contrast-higher-hsl), 0.075);
}

.footer-v5__back-to-top {
    color: hsl(var(--mu0-color-contrast-medium-hsl));
    transition: color 0.2s;
}

.footer-v5__back-to-top:hover {
    color: hsl(var(--mu0-color-contrast-high-hsl));
}

.footer-v5__link {
    color: hsl(var(--mu0-color-contrast-medium-hsl));
    text-decoration: none;
    transition: color 0.2s;
}

.footer-v5__link:hover {
    color: hsl(var(--mu0-color-contrast-high-hsl));
    text-decoration: underline;
}

.footer-v5__social-btn {
    display: block;
    text-decoration: none;
    color: hsl(var(--mu0-color-contrast-medium-hsl));
    transition: color 0.2s;
}

.footer-v5__social-btn .mu0-icon {
    display: block;
}

.footer-v5__social-btn:hover {
    color: hsl(var(--mu0-color-contrast-high-hsl));
}

/* utility classes */
.mu0-text-underline {
    text-decoration: underline;
}

.mu0-color-inherit {
    color: inherit;
}

.mu0-gap-xs {
    gap: var(--mu0-space-xs);
}

.mu0-flex-wrap {
    flex-wrap: wrap;
}

.mu0-flex {
    display: flex;
}

.mu0-color-contrast-medium {
    --mu0-color-o: 1;
    color: hsla(var(--mu0-color-contrast-medium-hsl), var(--mu0-color-o, 1));
}

.mu0-text-sm {
    font-size: var(--mu0-text-sm);
}

.mu0-gap-2xs {
    gap: var(--mu0-space-2xs);
}

.mu0-gap-sm {
    gap: var(--mu0-space-sm);
}

.mu0-items-center {
    align-items: center;
}

.mu0-flex-column {
    flex-direction: column;
}

.mu0-padding-top-sm {
    padding-top: var(--mu0-space-sm);
}

.mu0-border-top {
    --mu0-border-o: 1;
    border-top: var(--mu0-border-width, 1px) var(--mu0-border-style, solid) hsla(var(--mu0-color-contrast-lower-hsl), var(--mu0-border-o, 1));
}

.mu0-min-width-0 {
    min-width: 0;
}

.mu0-flex-grow-auto {
    flex-grow: 1;
    flex-basis: 0;
    min-width: 0;
}

.mu0-gap-3xs {
    gap: var(--mu0-space-3xs);
}

.mu0-margin-bottom-sm {
    margin-bottom: var(--mu0-space-sm);
}

.mu0-text-component :where(h1, h2, h3, h4) {
    line-height: var(--mu0-heading-line-height, 1.2);
    margin-top: calc(var(--mu0-space-md) * var(--mu0-space-multiplier, 1));
    margin-bottom: calc(var(--mu0-space-sm) * var(--mu0-space-multiplier, 1));
}

.mu0-text-component :where(p, blockquote, ul li, ol li) {
    line-height: var(--mu0-body-line-height, 1.4);
}

.mu0-text-component :where(ul, ol, p, blockquote, .mu0-text-component__block) {
    margin-bottom: calc(var(--mu0-space-sm) * var(--mu0-space-multiplier, 1));
}

.mu0-text-component :where(ul, ol) {
    padding-left: 1.25em;
}

.mu0-text-component ul :where(ul, ol),
.mu0-text-component ol :where(ul, ol) {
    padding-left: 1em;
    margin-bottom: 0;
}

.mu0-text-component ul {
    list-style-type: disc;
}

.mu0-text-component ol {
    list-style-type: decimal;
}

.mu0-text-component img {
    display: block;
    margin: 0 auto;
}

.mu0-text-component figcaption {
    margin-top: calc(var(--mu0-space-xs) * var(--mu0-space-multiplier, 1));
    font-size: var(--mu0-text-sm);
    text-align: center;
}

.mu0-text-component em {
    font-style: italic;
}

.mu0-text-component strong {
    font-weight: bold;
}

.mu0-text-component s {
    text-decoration: line-through;
}

.mu0-text-component u {
    text-decoration: underline;
}

.mu0-text-component mark {
    background-color: hsla(var(--mu0-color-accent-hsl), 0.2);
    color: inherit;
}

.mu0-text-component blockquote {
    padding-left: 1em;
    border-left: 4px solid hsl(var(--mu0-color-contrast-lower-hsl));
    font-style: italic;
}

.mu0-text-component hr {
    margin: calc(var(--mu0-space-md) * var(--mu0-space-multiplier, 1)) auto;
    background: hsl(var(--mu0-color-contrast-lower-hsl));
    height: 1px;
}

.mu0-text-component>*:first-child {
    margin-top: 0;
}

.mu0-text-component>*:last-child {
    margin-bottom: 0;
}

.mu0-text-component.mu0-line-height-xs {
    --mu0-heading-line-height: 1;
    --mu0-body-line-height: 1.1;
}

.mu0-text-component.mu0-line-height-sm {
    --mu0-heading-line-height: 1.1;
    --mu0-body-line-height: 1.2;
}

.mu0-text-component.mu0-line-height-md {
    --mu0-heading-line-height: 1.15;
    --mu0-body-line-height: 1.4;
}

.mu0-text-component.mu0-line-height-lg {
    --mu0-heading-line-height: 1.22;
    --mu0-body-line-height: 1.58;
}

.mu0-text-component.mu0-line-height-xl {
    --mu0-heading-line-height: 1.3;
    --mu0-body-line-height: 1.72;
}

.mu0-max-width-2xs {
    max-width: 32rem;
}

.mu0-grid {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
}

.mu0-grid>* {
    min-width: 0;
    grid-column-end: span 12;
}

.mu0-margin-bottom-xs {
    margin-bottom: var(--mu0-space-xs);
}

.mu0-gap-md {
    gap: var(--mu0-space-md);
}

.mu0-padding-y-xl {
    padding-top: var(--mu0-space-xl);
    padding-bottom: var(--mu0-space-xl);
}

@media not all and (min-width: 64rem) {
    .mu0-display\@md {
        display: none !important;
    }
}

.mu0-padding-bottom-xs {
    padding-bottom: var(--mu0-space-xs);
}

.mu0-border-bottom {
    --mu0-border-o: 1;
    border-bottom: var(--mu0-border-width, 1px) var(--mu0-border-style, solid) hsla(var(--mu0-color-contrast-lower-hsl), var(--mu0-border-o, 1));
}

.mu0-max-width-lg {
    max-width: 80rem;
}

.mu0-container {
    width: calc(100% - 2*var(--mu0-space-md));
    margin-left: auto;
    margin-right: auto;
}

.mu0-padding-y-lg {
    padding-top: var(--mu0-space-lg);
    padding-bottom: var(--mu0-space-lg);
}

@media(min-width: 32rem) {
    .mu0-col-6\@xs {
        grid-column-end: span 6;
    }
}

@media(min-width: 64rem) {
    .mu0-text-xs\@md {
        font-size: var(--mu0-text-xs);
    }

    .mu0-text-sm\@md {
        font-size: var(--mu0-text-sm);
    }

    .mu0-justify-between\@md {
        justify-content: space-between;
    }

    .mu0-flex-row\@md {
        flex-direction: row;
    }

    .mu0-text-base\@md {
        font-size: var(--mu0-text-base);
    }

    .mu0-hide\@md {
        display: none !important;
    }

    .mu0-items-center\@md {
        align-items: center;
    }

    .mu0-flex\@md {
        display: flex;
    }

    .mu0-col-3\@md {
        grid-column-end: span 3;
    }
}

@media(min-width: 80rem) {
    .mu0-col-8\@lg {
        grid-column-end: span 8;
    }

    .mu0-col-4\@lg {
        grid-column-end: span 4;
    }
}