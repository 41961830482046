/* variables */
:root {
	/* colors */
	--bj8-color-primary-hsl: 250, 84%, 54%;
	--bj8-color-bg-hsl: 0, 0%, 100%;
	--bj8-color-contrast-high-hsl: 230, 7%, 23%;
	--bj8-color-contrast-higher-hsl: 230, 13%, 9%;
	--bj8-color-contrast-lower-hsl: 240, 4%, 85%;
	--bj8-color-contrast-low-hsl: 240, 4%, 65%;

	/* spacing */
	--bj8-space-3xs: 0.25rem;
	--bj8-space-2xs: 0.375rem;

	/* typography */
	--bj8-text-sm: 0.833rem;
}

@media(min-width: 64rem) {
	:root {
		/* spacing */
		--bj8-space-3xs: 0.375rem;
		--bj8-space-2xs: 0.5625rem;

		/* typography */
		--bj8-text-sm: 1rem;
	}
}

/* icons */
.bj8-icon {
	height: var(--bj8-size, 1em);
	width: var(--bj8-size, 1em);
	display: inline-block;
	color: inherit;
	fill: currentColor;
	line-height: 1;
	flex-shrink: 0;
	max-width: initial;
}

/* component */
.breadcrumbs__item {
	display: inline-block;
	display: inline-flex;
	align-items: center;
}

/* utility classes */
.bj8-margin-left-2xs {
	margin-left: var(--bj8-space-2xs);
}

.bj8-color-contrast-lower {
	--bj8-color-o: 1;
	color: hsla(var(--bj8-color-contrast-lower-hsl), var(--bj8-color-o, 1));
}

.bj8-color-inherit {
	color: inherit;
}

.bj8-gap-2xs {
	gap: var(--bj8-space-2xs);
}

.bj8-flex-wrap {
	flex-wrap: wrap;
}

.bj8-flex {
	display: flex;
}

.bj8-text-sm {
	font-size: var(--bj8-text-sm);
}

.bj8-color-contrast-low {
	--bj8-color-o: 1;
	color: hsla(var(--bj8-color-contrast-low-hsl), var(--bj8-color-o, 1));
}

.bj8-margin-left-3xs {
	margin-left: var(--bj8-space-3xs);
}