/* -------------------------------- 

File#: _1_author
Title: Author
Descr: Author introduction card
Usage: codyhouse.co/license

-------------------------------- */
:root {
	--author-img-size: 4em;
}

.author {
	display: grid;
	grid-template-columns: var(--author-img-size) 1fr;
	grid-gap: var(--space-sm);
}

.author__img-wrapper {
	display: inline-block;
	border-radius: 50%;
	width: var(--author-img-size);
	height: var(--author-img-size);
	overflow: hidden;
	transition: transform 0.3s var(--ease-out-back);
}

.author__img-wrapper:hover {
	transform: scale(1.1);
}

.author__img-wrapper img {
	display: block;
	width: inherit;
	height: inherit;
	-o-object-fit: cover;
	object-fit: cover;
}

.author__content a {
	color: inherit;
}

.author__content a:hover {
	color: var(--color-primary);
}

.author--meta {
	--author-img-size: 3em;
	align-items: center;
	grid-gap: var(--space-xs);
}

.author--minimal {
	--author-img-size: 2.4em;
	align-items: center;
	grid-gap: var(--space-2xs);
}

.author--featured {
	--author-img-size: 6em;
	grid-template-columns: 1fr;
	justify-content: center;
	text-align: center;
}

.author--featured .author__img-wrapper {
	margin-left: auto;
	margin-right: auto;
}

.author__social {
	--size: 40px;
	width: var(--size);
	height: var(--size);
	display: flex;
	background-color: hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), 0.1);
	border-radius: 50%;
	transition: 0.2s;
}

.author__social .icon {
	--size: 16px;
	display: block;
	margin: auto;
	color: var(--color-contrast-higher);
	transition: color 0.2s;
}

.author__social:hover {
	background-color: hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), 0.075);
}

.author__social:hover .icon {
	color: var(--color-primary);
}