.pp-card {
	background-color: #c1d0e4;
	border-radius: 25px;
	padding: 0
}

.product-card {
	max-width: 100%;
	margin: 10px;
	box-shadow: 0 10px 15px rgba(0, 0, 100, 0.05);

	.product-header {
		max-height: 150px;
	}

	.product-picture {
		max-width: 200px;
		position: relative;
		top: -70px;
		width: 105%;
		left: -2.5%;
	}

	.card-details {
		background-color: white;
		border-radius: 25px;
		padding: 15px;

		.product-name {
			font-weight: 500;
			font-size: 2rem;
			margin: 0;
		}

		.product-description {
			font-size: 1rem;
			margin: 5px 0 30px 0;
		}

		.bottom-row {
			display: flex;
			justify-content: space-between;
			align-items: center;

			.price {
				font-size: 13pt;
				margin: 0;
			}
		}

	}

}


@media(min-width : 180px) and (max-width : 650px) {

	.product-card {
		max-width: 100%;
		margin: 5px;

		.product-header {
			max-height: 100px;

			.product-picture {
				position: relative;
				top: -50px;
				height: 150px;
				width: 150px;
				left: calc(50% - 75px);
			}
		}

		.card-details {

			.product-name {
				font-size: 11pt;
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 1;
				/* number of lines to show */
				line-clamp: 1;
				-webkit-box-orient: vertical;
			}

			.product-description {
				margin: 5px 0 15px 0;
				font-size: 12pt;
			}

			.bottom-row {
				.price {
					font-size: 10pt;
				}
			}
		}
	}

}