/* -------------------------------- 

File#: _1_drop-cap
Title: Drop Cap
Descr: Make the first letter of a paragraph larger in size
Usage: codyhouse.co/license

-------------------------------- */
/* reset */
*, *::after, *::before {
  box-sizing: border-box;
}

* {
  font: inherit;
  margin: 0;
  padding: 0;
  border: 0;
}

body {
  background-color: hsl(0, 0%, 100%);
  font-family: system-ui, sans-serif;
  color: hsl(230, 7%, 23%);
  font-size: 1rem;
}

h1, h2, h3, h4 {
  line-height: 1.2;
  color: hsl(230, 13%, 9%);
  font-weight: 700;
}

h1 {
  font-size: 2.0736rem;
}

h2 {
  font-size: 1.728rem;
}

h3 {
  font-size: 1.25rem;
}

h4 {
  font-size: 1.2rem;
}

ol, ul, menu {
  list-style: none;
}

button, input, textarea, select {
  background-color: transparent;
  border-radius: 0;
  color: inherit;
  line-height: inherit;
  appearance: none;
}

textarea {
  resize: vertical;
  overflow: auto;
  vertical-align: top;
}

a {
  color: hsl(250, 84%, 54%);
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

img, video, svg {
  display: block;
  max-width: 100%;
}

@media (min-width: 64rem) {
  body {
    font-size: 1.25rem;
  }

  h1 {
    font-size: 3.051rem;
  }

    h2 {
    font-size: 2.44rem;
  }

    h3 {
    font-size: 1.75rem;
  }

    h4 {
    font-size: 1.5625rem;
  }
}

/* variables */
:root {
  /* colors */
  --dj0-color-primary-hsl: 250, 84%, 54%;
  --dj0-color-bg-hsl: 0, 0%, 100%;
  --dj0-color-contrast-high-hsl: 230, 7%, 23%;
  --dj0-color-contrast-higher-hsl: 230, 13%, 9%;
}

/* component */
:root {
  --drop-cap-lines: 3;
}

.drop-cap::first-letter {
  float: left;
  line-height: 1;
  font-size: calc(1em * var(--drop-cap-lines) * var(--dj0-body-line-height, 1.4));
  padding: 0 0.125em 0 0;
  text-transform: uppercase;
  color: hsl(var(--dj0-color-contrast-higher-hsl));
}

.dj0-text-component .drop-cap::first-letter {
  font-size: calc(1em * var(--drop-cap-lines) * var(--dj0-body-line-height, 1.4) * var(--line-height-multiplier));
}