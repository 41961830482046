/* If you need to add @import statements, do so up here */

@import url('https://fonts.googleapis.com/css2?family=Patua+One&display=swap');

.sticky {
	position: sticky;
	top: 0;
}


.container {
	padding-right: 15px;
	padding-left: 15px;
	margin-right: auto;
	margin-left: auto;
}

@media (min-width: 768px) {
	.container {
		width: 750px;
	}
}

@media (min-width: 992px) {
	.container {
		width: 970px;
	}
}

@media (min-width: 1200px) {
	.container {
		width: 1170px;
	}
}