/* -------------------------------- 

File#: _2_dropdown
Title: Dropdown
Descr: A hoverable link that toggles the visibility of a dropdown list
Usage: codyhouse.co/license

-------------------------------- */

/* variables */
:root {
  /* colors */
  --dj3-color-primary-hsl: 250, 84%, 54%;
  --dj3-color-bg-hsl: 0, 0%, 100%;
  --dj3-color-contrast-high-hsl: 230, 7%, 23%;
  --dj3-color-contrast-higher-hsl: 230, 13%, 9%;
  --dj3-color-contrast-lower-hsl: 240, 4%, 85%;
  --dj3-color-bg-light-hsl: 0, 0%, 100%;

  /* spacing */
  --dj3-space-3xs: 0.25rem;
  --dj3-space-2xs: 0.375rem;
  --dj3-space-sm: 0.75rem;
}

@media(min-width: 64rem){
  :root {
    /* spacing */
    --dj3-space-3xs: 0.375rem;
    --dj3-space-2xs: 0.5625rem;
    --dj3-space-sm: 1.125rem;
  }
}

/* icons */
.dj3-icon {
  height: var(--dj3-size, 1em);
  width: var(--dj3-size, 1em);
  display: inline-block;
  color: inherit;
  fill: currentColor;
  line-height: 1;
  flex-shrink: 0;
  max-width: initial;
}

/* component */
:root {
  --dropdown-item-padding: var(--dj3-space-2xs) var(--dj3-space-sm);
}

.dropdown {
  position: relative;
}

.dropdown__menu {
  width: 200px;
  border-radius: 0.25em;
  padding: var(--dj3-space-3xs) 0;
  background-color: hsl(var(--dj3-color-bg-light-hsl));
  box-shadow: inset 0 0 0.5px 1px hsla(0, 0%, 100%, 0.075), 0 0.3px 0.4px rgba(0, 0, 0, 0.025),0 0.9px 1.5px rgba(0, 0, 0, 0.05), 0 3.5px 6px rgba(0, 0, 0, 0.1);
  z-index: 5;
  position: absolute;
  left: 0;
  top: 100%;
  --space-unit: 1rem;
  --text-unit: 1rem;
  font-size: var(--text-unit);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s, visibility 0s 0.2s;
}

@media (pointer: fine) {
  .dropdown__wrapper:hover > .dropdown__menu,
.dropdown__sub-wrapper:hover > .dropdown__menu {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.2s;
  }

  .dropdown__sub-wrapper:hover > .dropdown__menu {
    left: 100%;
  }
}
@media not all and (pointer: fine) {
  .dropdown__trigger-icon {
    display: none;
  }
}
.dropdown__item {
  display: block;
  text-decoration: none;
  color: hsl(var(--dj3-color-contrast-high-hsl));
  padding: var(--dropdown-item-padding);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: 0.2s;
}
.dropdown__item:hover, .dropdown__item.dropdown__item--hover {
  background-color: hsla(var(--dj3-color-contrast-higher-hsl), 0.075);
}

.dropdown__separator {
  height: 1px;
  background-color: hsl(var(--dj3-color-contrast-lower-hsl));
  margin: var(--dropdown-item-padding);
}

.dropdown__sub-wrapper {
  position: relative;
}
.dropdown__sub-wrapper > .dropdown__item {
  position: relative;
  padding-right: calc(var(--dj3-space-sm) + 12px);
}
.dropdown__sub-wrapper > .dropdown__item svg {
  position: absolute;
  display: block;
  width: 12px;
  height: 12px;
  right: var(--dj3-space-2xs);
  top: calc(50% - 6px);
}
.dropdown__sub-wrapper > .dropdown__menu {
  top: calc(var(--dj3-space-2xs) * -1);
  box-shadow: inset 0 0 0.5px 1px hsla(0, 0%, 100%, 0.075), 0 0.9px 1.5px rgba(0, 0, 0, 0.03),0 3.1px 5.5px rgba(0, 0, 0, 0.08),0 14px 25px rgba(0, 0, 0, 0.12);
}

.dropdown__menu {
  top: calc(100% + 4px);
}

.dropdown__sub-wrapper .dropdown__menu {
  top: calc(var(--dj3-space-2xs) * -1);
}

@media (pointer: fine) {
  .dropdown__menu--is-visible {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.2s;
  }
}
.dropdown__menu--is-hidden {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s, visibility 0s 0.2s;
}

.dropdown__sub-wrapper > .dropdown__menu--is-visible,
.dropdown__sub-wrapper > .dropdown__menu--is-hidden {
  left: 100%;
}
.dropdown__sub-wrapper > .dropdown__menu--is-visible.dropdown__menu--left,
.dropdown__sub-wrapper > .dropdown__menu--is-hidden.dropdown__menu--left {
  left: -100%;
}

/* utility classes */
.dj3-margin-left-3xs {
  margin-left: var(--dj3-space-3xs);
}

.dj3-items-center {
  align-items: center;
}

.dj3-inline-flex {
  display: inline-flex;
}

.dj3-inline-block {
  display: inline-block;
}