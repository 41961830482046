/* -------------------------------- 

File#: _2_article-v2
Title: Article v2
Descr: Article template
Usage: codyhouse.co/license

-------------------------------- */
/* reset */
*, *::after, *::before {
  box-sizing: border-box;
}

* {
  font: inherit;
  margin: 0;
  padding: 0;
  border: 0;
}

body {
  background-color: hsl(0, 0%, 100%);
  font-family: system-ui, sans-serif;
  color: hsl(230, 7%, 23%);
  font-size: 1rem;
}

h1, h2, h3, h4 {
  line-height: 1.2;
  color: hsl(230, 13%, 9%);
  font-weight: 700;
}

h1 {
  font-size: 2.0736rem;
}

h2 {
  font-size: 1.728rem;
}

h3 {
  font-size: 1.25rem;
}

h4 {
  font-size: 1.2rem;
}

ol, ul, menu {
  list-style: none;
}

button, input, textarea, select {
  background-color: transparent;
  border-radius: 0;
  color: inherit;
  line-height: inherit;
  appearance: none;
}

textarea {
  resize: vertical;
  overflow: auto;
  vertical-align: top;
}

a {
  color: hsl(250, 84%, 54%);
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

img, video, svg {
  display: block;
  max-width: 100%;
}

@media (min-width: 64rem) {
  body {
    font-size: 1.25rem;
  }

  h1 {
    font-size: 3.051rem;
  }

    h2 {
    font-size: 2.44rem;
  }

    h3 {
    font-size: 1.75rem;
  }

    h4 {
    font-size: 1.5625rem;
  }
}

/* variables */
:root {
  /* colors */
  --ag8-color-primary-hsl: 250, 84%, 54%;
  --ag8-color-bg-hsl: 0, 0%, 100%;
  --ag8-color-contrast-high-hsl: 230, 7%, 23%;
  --ag8-color-contrast-higher-hsl: 230, 13%, 9%;
  --ag8-color-contrast-lower-hsl: 240, 4%, 85%;
  --ag8-color-contrast-low-hsl: 240, 4%, 65%;
  --ag8-color-accent-hsl: 342, 89%, 48%;

  /* spacing */
  --ag8-space-xs: 0.5rem;
  --ag8-space-md: 1.25rem;
  --ag8-space-lg: 2rem;
  --ag8-space-xl: 3.25rem;
  --ag8-space-2xl: 5.25rem;
  --ag8-space-sm: 0.75rem;

  /* typography */
  --ag8-text-3xl: 2.488rem;
  --ag8-text-sm: 0.833rem;
  --ag8-text-sm: 0.833rem;
}

@media(min-width: 64rem){
  :root {
    /* spacing */
    --ag8-space-xs: 0.75rem;
    --ag8-space-md: 2rem;
    --ag8-space-lg: 3.125rem;
    --ag8-space-xl: 5.125rem;
    --ag8-space-2xl: 8.25rem;
    --ag8-space-sm: 1.125rem;

    /* typography */
    --ag8-text-3xl: 3.815rem;
    --ag8-text-sm: 1rem;
    --ag8-text-sm: 1rem;
  }
}

/* icons */
.ag8-icon {
  height: var(--ag8-size, 1em);
  width: var(--ag8-size, 1em);
  display: inline-block;
  color: inherit;
  fill: currentColor;
  line-height: 1;
  flex-shrink: 0;
  max-width: initial;
}

/* component */
.t-article-v2__cover {
  height: 200px;
  background-color: hsl(var(--ag8-color-contrast-low-hsl));
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
@media (min-width: 64rem) {
  .t-article-v2__cover {
    height: 400px;
  }
}

.t-article-v2__intro {
  background-color: hsl(var(--ag8-color-bg-hsl));
  padding: var(--ag8-space-md);
  margin-top: calc(-1 * var(--ag8-space-2xl));
}

.t-article-v2__divider {
  display: flex;
  align-items: center;
}
.t-article-v2__divider span {
  display: block;
  margin: 0 var(--ag8-space-xs);
  height: 10px;
  width: 10px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  background-color: hsl(var(--ag8-color-contrast-low-hsl));
}
.t-article-v2__divider::before, .t-article-v2__divider::after {
  content: "";
  display: block;
  height: 1px;
  width: auto;
  flex-grow: 1;
  background-color: hsl(var(--ag8-color-contrast-lower-hsl));
}

/* utility classes */
.ag8-justify-center {
  justify-content: center;
}

.ag8-gap-xs {
  gap: var(--ag8-space-xs);
}

.ag8-flex-wrap {
  flex-wrap: wrap;
}

.ag8-flex {
  display: flex;
}

.ag8-text-center {
  text-align: center;
}

.ag8-margin-bottom-md {
  margin-bottom: var(--ag8-space-md);
}

.ag8-max-width-md {
  max-width: 64rem;
}

.ag8-container {
  width: calc(100% - 2*var(--ag8-space-md));
  margin-left: auto;
  margin-right: auto;
}

:where(.ag8-media-wrapper-16\:9) {
  position: relative;
  height: 0;
}

.ag8-media-wrapper-16\:9 {
  padding-bottom: 56.25%;
}

.ag8-media-wrapper-16\:9 > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ag8-media-wrapper-16\:9 > *:not(iframe) {
  object-fit: cover;
}

@media (min-width: 90rem) {
  .ag8-text-component__block--outset {
    width: calc(100% + 12rem);  
  }

  .ag8-text-component__block--outset img {
    display: block;width: 100%;
  }

  .ag8-text-component__block--outset:not(.ag8-text-component__block--right) {
    margin-left: -6rem;
  }

  .ag8-text-component__block--right.ag8-text-component__block--outset {
    margin-right: -6rem;
  }
}

.ag8-grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
}

.ag8-grid > * {
  min-width: 0;
  grid-column-end: span 12;
}

@media (min-width: 48rem) {
  .ag8-text-component__block--left, .ag8-text-component__block--right {
    width: 45%;
  }

  .ag8-text-component__block--left img, .ag8-text-component__block--right img {
    display: block;
    width: 100%;
  }

  .ag8-text-component__block--left {
   float: left;
   margin-right: calc(var(--ag8-space-sm) * var(--ag8-space-multiplier, 1));
  }

  .ag8-text-component__block--right {
   float: right;
    margin-left: calc(var(--ag8-space-sm) * var(--ag8-space-multiplier, 1));
  }
}

@media (min-width: 90rem) {
  .ag8-text-component__block--left, .ag8-text-component__block--right {
    width: 50%;
  }
}

.ag8-text-gap-md {
  --ag8-space-multiplier: 1.25;
}

.ag8-line-height-lg {
  line-height: 1.58;
}

.ag8-text-component :where(h1, h2, h3, h4) {
  line-height: var(--ag8-heading-line-height, 1.2);
  margin-top: calc(var(--ag8-space-md) * var(--ag8-space-multiplier, 1));
  margin-bottom: calc(var(--ag8-space-sm) * var(--ag8-space-multiplier, 1));
}

.ag8-text-component :where(p, blockquote, ul li, ol li) {
  line-height: var(--ag8-body-line-height, 1.4);
}

.ag8-text-component :where(ul, ol, p, blockquote, .ag8-text-component__block) {
  margin-bottom: calc(var(--ag8-space-sm) * var(--ag8-space-multiplier, 1));
}

.ag8-text-component :where(ul, ol) {
  padding-left: 1.25em;
}

.ag8-text-component ul :where(ul, ol), .ag8-text-component ol :where(ul, ol) {
  padding-left: 1em;
  margin-bottom: 0;
}

.ag8-text-component ul {
  list-style-type: disc;
}

.ag8-text-component ol {
  list-style-type: decimal;
}

.ag8-text-component img {
  display: block;
  margin: 0 auto;
}

.ag8-text-component figcaption {
  margin-top: calc(var(--ag8-space-xs) * var(--ag8-space-multiplier, 1));
  font-size: var(--ag8-text-sm);
  text-align: center;}

.ag8-text-component em {
  font-style: italic;
}

.ag8-text-component strong {
  font-weight: bold;
}

.ag8-text-component s {
  text-decoration: line-through;
}

.ag8-text-component u {
  text-decoration: underline;
}

.ag8-text-component mark {
  background-color: hsla(var(--ag8-color-accent-hsl), 0.2);
  color: inherit;
}

.ag8-text-component blockquote {
  padding-left: 1em;
  border-left: 4px solid hsl(var(--ag8-color-contrast-lower-hsl));
  font-style: italic;
}

.ag8-text-component hr {
  margin: calc(var(--ag8-space-md) * var(--ag8-space-multiplier, 1)) auto;
  background: hsl(var(--ag8-color-contrast-lower-hsl));
  height: 1px;
}

.ag8-text-component > *:first-child {
  margin-top: 0;
}

.ag8-text-component > *:last-child {
  margin-bottom: 0;
}

.ag8-text-component.ag8-line-height-xs {
  --ag8-heading-line-height: 1;
  --ag8-body-line-height: 1.1;
}

.ag8-text-component.ag8-line-height-sm {
  --ag8-heading-line-height: 1.1;
  --ag8-body-line-height: 1.2;
}

.ag8-text-component.ag8-line-height-md {
  --ag8-heading-line-height: 1.15;
  --ag8-body-line-height: 1.4;
}

.ag8-text-component.ag8-line-height-lg {
  --ag8-heading-line-height: 1.22;
  --ag8-body-line-height: 1.58;
}

.ag8-text-component.ag8-line-height-xl {
  --ag8-heading-line-height: 1.3;
  --ag8-body-line-height: 1.72;
}

.ag8-margin-bottom-xl {
  margin-bottom: var(--ag8-space-xl);
}

.ag8-max-width-adaptive-sm {
  max-width: 32rem;
}

@media(min-width: 48rem) {
  .ag8-max-width-adaptive-sm {
    max-width: 48rem;
  }
}

.ag8-margin-top-lg {
  margin-top: var(--ag8-space-lg);
}

.ag8-text-sm {
  font-size: var(--ag8-text-sm);
}

.ag8-text-3xl {
  font-size: var(--ag8-text-3xl);
}

.ag8-margin-bottom-lg {
  margin-bottom: var(--ag8-space-lg);
}

.ag8-padding-bottom-lg {
  padding-bottom: var(--ag8-space-lg);
}

.ag8-col-6 {
  grid-column-end: span 6;
}