.mv--tg {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
}

.team {
  width: 100%;
  text-align: center;
  margin-bottom: 24px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.09);
}

.team .team-img {
  position: relative;
  font-size: 0;
  color: #353535;
}

.team .team-img img {
  width: 100%;
  height: auto;
}

.team .team-content {
  padding: 20px;
}

.team .team-content h2 {
  margin: 0 0 5px 0;
  color: #353535;
  font-size: 25px;
  font-weight: 600;
  letter-spacing: 2px;
}

.team .team-content h3 {
  margin: 0 0 15px 0;
  color: #353535;
  font-size: 14px;
  font-weight: 300;
}

.team .team-content p {
  margin: 0 0 15px 0;
  color: #353535;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL1VzZXJzL2Rhbi9EZXZlbG9wbWVudC9icmlkZ2V0b3duLWxlZ2Fkb3gtcmVkdXgvc3JjL19jb21wb25lbnRzL2Fib3V0Iiwic291cmNlcyI6WyJ0ZWFtLnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDQztFQUNBO0VBQ0E7OztBQUdEO0VBRUM7RUFDQTtFQUVBO0VBQ0E7OztBQUdEO0VBQ0M7RUFDQTtFQUNBOzs7QUFHRDtFQUNDO0VBQ0E7OztBQUdEO0VBQ0M7OztBQUdEO0VBQ0M7RUFDQTtFQUNBO0VBQ0E7RUFDQTs7O0FBR0Q7RUFDQztFQUNBO0VBQ0E7RUFDQTs7O0FBR0Q7RUFDQztFQUNBO0VBQ0E7RUFDQTtFQUNBIiwic291cmNlc0NvbnRlbnQiOlsiLm12LS10ZyB7XG5cdGRpc3BsYXk6IGdyaWQ7XG5cdGdyaWQtdGVtcGxhdGUtY29sdW1uczogcmVwZWF0KGF1dG8tZml0LCBtaW5tYXgoMjAwcHgsIDFmcikpO1xuXHRnYXA6IDFyZW07XG59XG5cbi50ZWFtIHtcblx0Ly8gcG9zaXRpb246IHJlbGF0aXZlO1xuXHR3aWR0aDogMTAwJTtcblx0dGV4dC1hbGlnbjogY2VudGVyO1xuXHQvLyBtYXgtd2lkdGg6IDUwJTtcblx0bWFyZ2luLWJvdHRvbTogMjRweDtcblx0Ym94LXNoYWRvdzogMCA0cHggOHB4IDAgcmdiYSgwLCAwLCAwLCAwLjA5KTtcbn1cblxuLnRlYW0gLnRlYW0taW1nIHtcblx0cG9zaXRpb246IHJlbGF0aXZlO1xuXHRmb250LXNpemU6IDA7XG5cdGNvbG9yOiAjMzUzNTM1O1xufVxuXG4udGVhbSAudGVhbS1pbWcgaW1nIHtcblx0d2lkdGg6IDEwMCU7XG5cdGhlaWdodDogYXV0bztcbn1cblxuLnRlYW0gLnRlYW0tY29udGVudCB7XG5cdHBhZGRpbmc6IDIwcHg7XG59XG5cbi50ZWFtIC50ZWFtLWNvbnRlbnQgaDIge1xuXHRtYXJnaW46IDAgMCA1cHggMDtcblx0Y29sb3I6ICMzNTM1MzU7XG5cdGZvbnQtc2l6ZTogMjVweDtcblx0Zm9udC13ZWlnaHQ6IDYwMDtcblx0bGV0dGVyLXNwYWNpbmc6IDJweDtcbn1cblxuLnRlYW0gLnRlYW0tY29udGVudCBoMyB7XG5cdG1hcmdpbjogMCAwIDE1cHggMDtcblx0Y29sb3I6ICMzNTM1MzU7XG5cdGZvbnQtc2l6ZTogMTRweDtcblx0Zm9udC13ZWlnaHQ6IDMwMDtcbn1cblxuLnRlYW0gLnRlYW0tY29udGVudCBwIHtcblx0bWFyZ2luOiAwIDAgMTVweCAwO1xuXHRjb2xvcjogIzM1MzUzNTtcblx0Zm9udC1zaXplOiAxNnB4O1xuXHRmb250LXdlaWdodDogNDAwO1xuXHRsaW5lLWhlaWdodDogMjRweDtcbn0iXX0= */