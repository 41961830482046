/* -------------------------------- 

File#: _3_hero-video-bg
Title: Hero Background Video
Descr: Hero section variation w/ background video
Usage: codyhouse.co/license

-------------------------------- */
/* variables */
:root {
  /* colors */
  --hn4-color-primary-hsl: 250, 84%, 54%;
  --hn4-color-bg-hsl: 0, 0%, 100%;
  --hn4-color-contrast-high-hsl: 230, 7%, 23%;
  --hn4-color-contrast-higher-hsl: 230, 13%, 9%;
  --hn4-color-black-hsl: 230, 13%, 9%;
  --hn4-color-accent-hsl: 342, 89%, 48%;
  --hn4-color-contrast-lower-hsl: 240, 4%, 85%;

  /* spacing */
  --hn4-space-2xs: 0.375rem;
  --hn4-space-md: 1.25rem;
  --hn4-space-2xl: 5.25rem;
  --hn4-space-sm: 0.75rem;
  --hn4-space-xs: 0.5rem;

  /* typography */
  --hn4-text-sm: 0.833rem;
  --hn4-text-sm: 0.833rem;
}

@media(min-width: 64rem){
  :root {
    /* spacing */
    --hn4-space-2xs: 0.5625rem;
    --hn4-space-md: 2rem;
    --hn4-space-2xl: 8.25rem;
    --hn4-space-sm: 1.125rem;
    --hn4-space-xs: 0.75rem;

    /* typography */
    --hn4-text-sm: 1rem;
    --hn4-text-sm: 1rem;
  }
}

/* icons */
.hn4-icon {
  height: var(--hn4-size, 1em);
  width: var(--hn4-size, 1em);
  display: inline-block;
  color: inherit;
  fill: currentColor;
  line-height: 1;
  flex-shrink: 0;
  max-width: initial;
}

.hn4-icon--sm {
  --hn4-size: 24px;
}

.hn4-icon--lg {
  --hn4-size: 48px;
}

.hn4-icon--is-spinning {
  animation: hn4-icon-spin 1s infinite linear;
}

@keyframes hn4-icon-spin {
  0% {
  transform: rotate(0deg);
}
  100% {
  transform: rotate(360deg);
}
}

/* component */
.hero-video-bg__video {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  z-index: 1;
}
.hero-video-bg__video video {
  position: relative;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
          transform: translateX(-50%) translateY(-50%);
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  max-width: none;
}
@supports ((-o-object-fit: cover) or (object-fit: cover)) {
  .hero-video-bg__video video {
    -o-object-fit: cover;
       object-fit: cover;
    height: 100%;
    width: 100%;
  }
}
@supports ((-o-object-fit: cover) or (object-fit: cover)) and (-ms-ime-align: auto) {
  .hero-video-bg__video video {
    height: auto;
    width: auto;
  }
}
.hero-video-bg__video img {
  display: none;
}
@media (prefers-reduced-motion: reduce) {
  .hero-video-bg__video video {
    visibility: hidden;
  }
  .hero-video-bg__video img {
    display: block;
    position: absolute;
    -o-object-fit: cover;
       object-fit: cover;
    height: 100%;
    width: 100%;
  }
}

.hero-video-bg__play-btn {
  --btn-size: 80px;
  width: var(--btn-size);
  height: var(--btn-size);
  display: inline-flex;
  background-color: hsla(var(--hn4-color-contrast-higher-hsl), 0.85);
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
}
.hero-video-bg__play-btn .hn4-icon {
  display: block;
  margin: auto;
  color: hsl(var(--hn4-color-bg-hsl));
  font-size: 32px;
  transition: -webkit-transform 0.3s cubic-bezier(0.34, 1.56, 0.64, 1);
  transition: transform 0.3s cubic-bezier(0.34, 1.56, 0.64, 1);
  transition: transform 0.3s cubic-bezier(0.34, 1.56, 0.64, 1), -webkit-transform 0.3s cubic-bezier(0.34, 1.56, 0.64, 1);
}
.hero-video-bg__play-btn:hover {
  background-color: hsla(var(--hn4-color-contrast-higher-hsl), 1);
}
.hero-video-bg__play-btn:hover .hn4-icon {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

/* utility classes */
.hn4-color-bg {
  --hn4-color-o: 1;
  color: hsla(var(--hn4-color-bg-hsl), var(--hn4-color-o, 1));
}

:where(.hn4-media-wrapper-16\:9) {
  position: relative;
  height: 0;
}

.hn4-media-wrapper-16\:9 {
  padding-bottom: 56.25%;
}

.hn4-media-wrapper-16\:9 > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.hn4-media-wrapper-16\:9 > *:not(iframe) {
  object-fit: cover;
}

.hn4-sr-only {
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  width: 1px;
  height: 1px;
  overflow: hidden;
  padding: 0;
  border: 0;
  white-space: nowrap;
}

.hn4-shadow-md {
  box-shadow:0 0.9px 1.5px rgba(0, 0, 0, 0.03),0 3.1px 5.5px rgba(0, 0, 0, 0.08),0 14px 25px rgba(0, 0, 0, 0.12);
}

.hn4-overflow-auto {
  overflow: auto;
}

.hn4-max-height-100\% {
  max-height: 100%;
}

.hn4-max-width-md {
  max-width: 64rem;
}

.hn4-width-100\% {
  width: 100%;
}

.hn4-padding-x-md {
  padding-left: var(--hn4-space-md);
  padding-right: var(--hn4-space-md);
}

.hn4-bg-black {
  --hn4-bg-o: 1;
  background-color: hsla(var(--hn4-color-black-hsl), var(--hn4-bg-o, 1));
}

.hn4-flex-center {
  justify-content: center;
  align-items: center;
}

.hn4-flex {
  display: flex;
}

.hn4-text-component :where(h1, h2, h3, h4) {
  line-height: var(--hn4-heading-line-height, 1.2);
  margin-top: calc(var(--hn4-space-md) * var(--hn4-space-multiplier, 1));
  margin-bottom: calc(var(--hn4-space-sm) * var(--hn4-space-multiplier, 1));
}

.hn4-text-component :where(p, blockquote, ul li, ol li) {
  line-height: var(--hn4-body-line-height, 1.4);
}

.hn4-text-component :where(ul, ol, p, blockquote, .hn4-text-component__block) {
  margin-bottom: calc(var(--hn4-space-sm) * var(--hn4-space-multiplier, 1));
}

.hn4-text-component :where(ul, ol) {
  padding-left: 1.25em;
}

.hn4-text-component ul :where(ul, ol), .hn4-text-component ol :where(ul, ol) {
  padding-left: 1em;
  margin-bottom: 0;
}

.hn4-text-component ul {
  list-style-type: disc;
}

.hn4-text-component ol {
  list-style-type: decimal;
}

.hn4-text-component img {
  display: block;
  margin: 0 auto;
}

.hn4-text-component figcaption {
  margin-top: calc(var(--hn4-space-xs) * var(--hn4-space-multiplier, 1));
  font-size: var(--hn4-text-sm);
  text-align: center;}

.hn4-text-component em {
  font-style: italic;
}

.hn4-text-component strong {
  font-weight: bold;
}

.hn4-text-component s {
  text-decoration: line-through;
}

.hn4-text-component u {
  text-decoration: underline;
}

.hn4-text-component mark {
  background-color: hsla(var(--hn4-color-accent-hsl), 0.2);
  color: inherit;
}

.hn4-text-component blockquote {
  padding-left: 1em;
  border-left: 4px solid hsl(var(--hn4-color-contrast-lower-hsl));
  font-style: italic;
}

.hn4-text-component hr {
  margin: calc(var(--hn4-space-md) * var(--hn4-space-multiplier, 1)) auto;
  background: hsl(var(--hn4-color-contrast-lower-hsl));
  height: 1px;
}

.hn4-text-component > *:first-child {
  margin-top: 0;
}

.hn4-text-component > *:last-child {
  margin-bottom: 0;
}

.hn4-text-component.hn4-line-height-xs {
  --hn4-heading-line-height: 1;
  --hn4-body-line-height: 1.1;
}

.hn4-text-component.hn4-line-height-sm {
  --hn4-heading-line-height: 1.1;
  --hn4-body-line-height: 1.2;
}

.hn4-text-component.hn4-line-height-md {
  --hn4-heading-line-height: 1.15;
  --hn4-body-line-height: 1.4;
}

.hn4-text-component.hn4-line-height-lg {
  --hn4-heading-line-height: 1.22;
  --hn4-body-line-height: 1.58;
}

.hn4-text-component.hn4-line-height-xl {
  --hn4-heading-line-height: 1.3;
  --hn4-body-line-height: 1.72;
}

.hn4-text-center {
  text-align: center;
}

.hn4-z-index-2 {
  z-index: 2;
}

.hn4-position-relative {
  position: relative;
}

.hn4-max-width-adaptive-sm {
  max-width: 32rem;
}

@media(min-width: 48rem) {
  .hn4-max-width-adaptive-sm {
    max-width: 48rem;
  }
}

.hn4-container {
  width: calc(100% - 2*var(--hn4-space-md));
  margin-left: auto;
  margin-right: auto;
}

.hn4-padding-y-2xl {
  padding-top: var(--hn4-space-2xl);
  padding-bottom: var(--hn4-space-2xl);
}

.hn4-color-contrast-higher {
  --hn4-color-o: 1;
  color: hsla(var(--hn4-color-contrast-higher-hsl), var(--hn4-color-o, 1));
}

.hn4-opacity-90\% {
  opacity: 0.9;
}

.hn4-margin-bottom-2xs {
  margin-bottom: var(--hn4-space-2xs);
}

.hn4-text-sm {
  font-size: var(--hn4-text-sm);
}

.hn4-bg-opacity-90\% {
  --hn4-bg-o: 0.9;
}